import React from "react";

const About = () => {
  return (
    <div
      name="about"
      className="w-full h-screen bg-gradient-to-b from-gray-800 to-black text-white"
    >
      <div className="max-w-screen-lg p-4 mx-auto flex flex-col justify-center w-full h-full">
        <div className="pb-8">
          <p className="text-4xl font-bold inline border-b-4 border-gray-500">
            About
          </p>
        </div>

        <p className="text-xl mt-20">
        Hello! I’m excited to take my first steps in the tech-industry and be part of shaping the future. Although I may currently lack professional experience, I am passionate and adaptable, and am determined to match the work ethic of seasoned professionals in the field. I’m open to any opportunities that will help me gain valuable knowledge and real-world experience, which is why I am more than willing to work for free.

        </p>

        <br />

        <p className="text-xl">
           I have a strong passion for learning and adapting. I want to be part of something new and help build the future. Please use the Contact section to reach me. I will respond within 2 days.
        </p>
      </div>
    </div>
  );
};

export default About;
